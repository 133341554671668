body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.8;
  font-size: 18px;
  word-wrap: break-word;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main-div {
  display: flex;
  width: 90%;
  margin: auto 16px;
}
.row {
  flex: 0 1;
  flex-direction: row;
}
.col {
  flex: 1;
  flex-direction: column;
}
.col-2 {
  flex: 2;
  flex-direction: column;
}
.align-to-right {
  justify-content: flex-end;
}
.align-to-left {
  justify-content: flex-start;
}
.align-to-center {
  justify-content: center;
}
.right-float {
  float: right;
}
.bg-white {
  background-color: #FFFFFF;
}
.light-grey {
  background-color: #eaeaea;
}
.nav {
  width: 310px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  background-color: #eb6c20;
}
.nav-open {
  left: 0;
}
.nav-closed {
  left: -312px;
}
ul {
  list-style: none;
  padding-inline-start: 0px;
}
li {
  padding: 16px 20px;
}
li:hover {
  background-color: #fcd3bb;
  color: #f6b28a;
}
.li_text {
  font-size: large;
  color: white;
  text-decoration: none;
  font-weight: bold;
}
li a .li_text:hover {
  font-size: large;
  color: #f6b28a;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
}
.container {
  margin: auto 18px;
  width: 90%;
}
.inner-container {
  justify-content: center;
}
.page-title {
  align-self: center;
  font-size: xx-large;
  font-weight: BOLD;
  color: #eb6c20;
  text-align: center;
}
.aboutus {
  word-wrap: break-word;
  line-height: 1.8;
  font-size: 18px;
  margin-top: 15px;
}
.menu_icon_div {
  margin-top: 40px;
}
.menu_icon {
  width: 35px;
  height: 5px;
  background-color: #eb6c20;
  margin: 6px 0;
}
.close_icon {
  color: white;
  font-size: 25px;
  font-weight: bold;
  text-align: end;
  padding: 20px;
  cursor: pointer;
}
.wd-100 {
  width: 100%;
}
.wd-70 {
  width: 70%;
}
.wd-50 {
  width: 50%;
}
.wd-65 {
  width: 65%;
}
.wd-40 {
  width: 40%
}
.wd-30 {
  width: 30%
}
.wd-25 {
  width: 25%
}
.wd-20 {
  width: 20%
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right; 
}
.text-left {
  text-align: left; 
}
.display-flex {
  display: inline-flex;
}
.pd-8 {
  padding: 8px;
}
.pd-10 {
  padding: 10px;
}
.pd-20 {
  padding: 20px;
}
.pd-l-16 {
  padding-left: 16px;
}
.text-size-16 {
  font-size: 16px;
}
.text-size-18 {
  font-size: 18px;
}
.text-size-24 {
  font-size: 24px;
}
.text-size-30 {
  font-size: 30px;
}
.text-white {
  color: white;
}
.text-wrap {
  word-wrap: break-word;
}
.text-bold {
  font-weight: bold;
}
.text-shadow {
  text-shadow: 1px 1px 1px rgba(151,71,0,.35);
}
.text-primary {
  color: #eb6c20
}
.text-secondary {
  color: #dad9da
}
.text-red {
  color: red;
}
.text-green {
  color: green;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-35 {
  margin-left: 35px;
}
.bold-font {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}
.circle {
  background-color: #eb6c20;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  text-align: center;
  background: #eb6c20;
  padding: 10px;
  margin: 10px;
  width: 91%;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  box-shadow: 1px 1px #888888;
}
.circle-div {
  justify-content: center;
}
.shadow-div {
  box-shadow: 3px 4px 3px -1px rgba(100, 100, 100, 0.1);
  width: 100%;
  margin-bottom: 20px;
}
.outer-box {
  margin-top: 20px;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(45deg, #eb6c20, #fdb11b) 1;
  box-shadow: 1px 1px #888888;
}
.input-box {
  border: 1px solid #dad9da;
  border-radius: 10px;
  width: 50%;
}
.primary-button {
  padding: 10px 30px;
  margin-right: 20px;
  background-image: linear-gradient(#eb6c20, #fdb11b);
  border: 1px solid #dad9da; 
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  width: 80%;
  margin: auto;
}
.primary-button:hover {
  background-image: linear-gradient(#fdb11b, #eb6c20);
}
.secondary-button {
  padding: 10px 30px;
  margin-right: 20px;
  border: 1px solid #dad9da; 
  font-weight: bold;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  color: #7c7777;
}
.required {
  color: red;
}
.auto-margin {
  margin: auto;
}
.tiffinImg {
  height: 100px;
}
.m-20{
  margin: 20px
}
.p-20{
  padding: 20px
}
.imgView {
  width: 30%;
  display: inline-flex;
  margin-right: 30px;
}
.dropdownView {
  width: 60%;
  display: inline-flex;
  vertical-align: top;
}
select {
  margin-top: 2% !important;
  font-size: 19px !important;
  text-align: center !important;
  width: 100%;
  padding: 6px 12px !important;
  background-color: #eb6c20;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}
option {
  min-height: 1.5rem !important;
  padding: 14px !important;
}
.stepCircle {
  width: 40px;
  border-radius: 100%;
  text-align: center;
  justify-content: center;
}
.activeStepCircle {
  background-color: #eb6c20;
}
.inActiveStepCircle {
  background-color: #dad9da;
}
.circleText {
  color: white;
  vertical-align: middle;
  font-weight: bold;
}
.line {
  height: 1px;
  vertical-align: middle;
  width: 30%;
  margin-left: 10px;
}
.activeLine {
  border: 1px solid #eb6c20;
  background-color: #eb6c20;
}
.inActiveLine {
  border: 1px solid #dad9da;
  background-color: #dad9da;
}
.addressView {
  width: 100%;
  display: inline-flex;
  vertical-align: top;
}
input[type='radio'] {
  accent-color: #eb6c20;
}
.setAsDefault {
  color: #eb6c20;
  cursor: pointer;
}
.mapContainer {
  width: 80%;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.mapStyle {
  width: 60%; 
  margin: auto;
  height: 300px;
}
.modal::backdrop {
  background: hsl(0 0% 0% / 50%);
}
.modal {
  max-width: 20rem;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  position: relative;
  border-radius: 1rem;
  position: fixed;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  border: 1px solid #ccc;
}

.modal-close-btn {
  font-size: 0.75em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
}
.qrView {
  width: 100%;
  display: block;
}
.paymentSummary {
  width: 100%;
  display: block;
}
.buttonDiv {
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  width: 50%;
}
.labelDiv {
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  width: 30%;
}
.outer-border {
  border-image: linear-gradient(45deg,#eb6c20,#fdb11b) 1;
  border-style: solid;
  border-width: 3px;
  box-shadow: 1px 1px #888;
  margin-top: 20px;
}

@media only screen and (min-width: 600px) {
  .container {
    margin: auto;
    width: 75%;
  }
  .main-div {
    display: flex;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .circle {
    padding: 10px;
    margin: 10px;
    width: 95%;
    font-size: 1.5em;
  }
  .tiffinImg {
    height: 250px;
  }
  .primary-button {
    /* width: 40%; */
    margin: auto;
  }
  select {
    width: 80%;
  }
  .addressView {
    width: 65%;
  }
  .line {
    width: 30%;
    margin-left: 6px;
  }
  .qrView {
    width: 40%;
    display: inline-flex;
    margin-right: 30px;
  }
  .paymentSummary {
    width: 55%;
    display: inline-flex;
    vertical-align: top;
  }
  .buttonDiv {
    width: 25%;
    display: inline-flex;
  }
  .outer-box {
    padding: 20px;
  }
  .labelDiv {
    width: 30%;
  }
  .input-box {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .container {
    margin: auto;
    width: 93%;
  }
  .main-div {
    display: flex;
    width: 93%;
    margin-left: auto;
    margin-right: auto;
  }
  .page-title {
    align-self: center;
    font-size: xx-large;
    font-weight: BOLD;
    color: #eb6c20;
    text-align: center;
  }
  .circle {
    padding: 10px;
    margin: 10px;
    width: 44%;
    font-size: 1.5em;
  }
  .tiffinImg {
    height: 300px;
  }
  .primary-button {
    /* width: 40%; */
    margin: auto;
  }
  select {
    width: 80%;
  }
  .addressView {
    width: 65%;
  }
  .line {
    height: 1px;
    width: 48%;
    vertical-align: middle;
    margin-left: 30px;
  }
  .qrView {
    width: 40%;
    display: inline-flex;
    margin-right: 30px;
  }
  .paymentSummary {
    width: 55%;
    display: inline-flex;
    vertical-align: top;
  }
  .buttonDiv {
    width: 25%;
    display: inline-flex;
  }
  .outer-box {
    padding: 20px;
  }
  .labelDiv {
    width: 30%;
  }
  .input-box {
    width: 50%;
  }
}
@media only screen and (min-width: 992px) {
  .container {
    margin: auto;
    width: 70%;
  }
  .main-div {
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto
  }
  .page-title {
    align-self: center;
    font-size: xx-large;
    font-weight: BOLD;
    color: #eb6c20;
    text-align: center;
  }
  .circle {
    padding: 10px;
    margin: 10px;
    width: 44%;
    font-size: 1.5em;
  }
  .tiffinImg {
    height: 300px;
  }
  .primary-button {
    /* width: 40%; */
    margin: auto;
  }
  select {
    width: 80%;
  }
  .addressView {
    width: 65%;
  }
  .line {
    height: 1px;
    width: 48%;
    vertical-align: middle;
    margin-left: 25px;
  }
  .qrView {
    width: 40%;
    display: inline-flex;
    margin-right: 30px;
  }
  .paymentSummary {
    width: 55%;
    display: inline-flex;
    vertical-align: top;
  }
  .buttonDiv {
    width: 25%;
    display: inline-flex;
  }
  .outer-box {
    padding: 20px;
  }
  .labelDiv {
    width: 30%;
  }
  .input-box {
    width: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    margin: auto;
    width: 60%;
  }
  .main-div {
    display: flex;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .page-title {
    align-self: center;
    font-size: xx-large;
    font-weight: bold;
    color: #eb6c20;
    text-align: center;
  }
  .circle {
    padding: 10px;
    margin: 10px;
    width: 44%;
    font-size: 1.5em;
  }
  .tiffinImg {
    height: 300px;
  }
  .primary-button {
    /* width: 40%; */
    margin: auto;
  }
  select {
    width: 80%;
  }
  .addressView {
    width: 65%;
  }
  .line {
    height: 1px;
    width: 48%;
    vertical-align: middle;
    margin-left: 30px;
  }
  .qrView {
    width: 40%;
    display: inline-flex;
    margin-right: 30px;
  }
  .paymentSummary {
    width: 55%;
    display: inline-flex;
    vertical-align: top;
  }
  .buttonDiv {
    width: 25%;
    display: inline-flex; 
  }
  .outer-box {
    padding: 20px;
  }
  .labelDiv {
    width: 30%;
  }
  .input-box {
    width: 50%;
  }
}